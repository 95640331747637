import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4dfcbf07&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppUSP: require('/home/vsts/work/1/a/NuxtApp/components/app/USP.vue').default,BaseCollapseTransition: require('/home/vsts/work/1/a/NuxtApp/components/base/CollapseTransition.vue').default,AppHeaderMenuBtn: require('/home/vsts/work/1/a/NuxtApp/components/app/header/MenuBtn.vue').default,AppHeaderSearchInput: require('/home/vsts/work/1/a/NuxtApp/components/app/header/SearchInput.vue').default,AppHeaderUserDropdown: require('/home/vsts/work/1/a/NuxtApp/components/app/header/UserDropdown.vue').default,AppHeaderFavorite: require('/home/vsts/work/1/a/NuxtApp/components/app/header/Favorite.vue').default,AppHeaderShoppingCartBtn: require('/home/vsts/work/1/a/NuxtApp/components/app/header/ShoppingCartBtn.vue').default,AppHeaderMenuBar: require('/home/vsts/work/1/a/NuxtApp/components/app/header/MenuBar.vue').default,AppBreadcrumbs: require('/home/vsts/work/1/a/NuxtApp/components/app/Breadcrumbs.vue').default})
